// extracted by mini-css-extract-plugin
export var blogPreviews = "hire-a-grad-module--blog-previews--REsR6";
export var blogTitle = "hire-a-grad-module--blog-title--6wAyU";
export var cardImage = "hire-a-grad-module--card-image--e98q5";
export var cardInfo = "hire-a-grad-module--card-info--zA1MA";
export var carouselSection = "hire-a-grad-module--carousel-section--jUvXu";
export var customPlacements = "hire-a-grad-module--custom-placements--FPSuV";
export var loadMoreButton = "hire-a-grad-module--load-more-button--+2yrz";
export var logosSection = "hire-a-grad-module--logos-section--uOQg5";
export var meetGrads = "hire-a-grad-module--meet-grads--Tn0RN";
export var partnerName = "hire-a-grad-module--partner-name--Wsb0h";
export var previewCard = "hire-a-grad-module--preview-card--JHFXk";
export var readMore = "hire-a-grad-module--read-more--eq2If";
export var relevantBlogPosts = "hire-a-grad-module--relevant-blog-posts--Zedne";
export var sectionButton = "hire-a-grad-module--section-button--8nFts";
export var sectionHeading = "hire-a-grad-module--section-heading--EizfJ";
export var skillsNeed = "hire-a-grad-module--skills-need--UdjRr";